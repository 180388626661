import React, { useEffect } from 'react';

// import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import anime from 'animejs';
import styles from './IconPowerGrid.module.scss';

const IconPowerGrid = ({ shouldStart = false, id = '' }) => {
    const GridAnimation = () => {
        anime({
            targets: `.${id}gridLine`,
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'linear',
            duration: 500,
            direction: 'forward',
            loop: false,
        });
    };

    useEffect(() => {
        shouldStart ? GridAnimation() : null;
    });

    return (
        <div className={styles['IconPowerGrid']}>
            <svg
                viewBox="0 0 50 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.613 17v-3a2 2 0 0 1 2-2h33a2 2 0 0 1 2 2v3"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                />
                <path
                    className={id + 'gridLine'}
                    d="M19.113 23h11.5l-13 12h17"
                    stroke="#feca01"
                    strokeWidth={2}
                    strokeLinecap="round"
                />
                <path
                    d="M14.613 12v5m22-5v5"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                />
                <path
                    d="M12.64 44.773a1 1 0 0 0 1.947.454l-1.948-.454zm23.999.452a1 1 0 1 0 1.949-.45l-1.95.45zM19.714 18.562l-.974-.225v.003l.974.222zm11.798 0 .974-.225-.974.225zM17.113 30l.974.227.001-.005-.975-.222zM37.04 42.517l-.974.225.974-.225zM28.74 6.55l-.974.225.974-.225zM24.435 6h2.357V4h-2.357v2zM20.69 18.787l2.77-12.012-1.95-.45-2.771 12.012 1.949.45zm7.077-12.012 2.772 12.012 1.948-.45-2.771-12.012-1.95.45zm-13.18 38.452 3.5-15-1.948-.454-3.5 15 1.948.454zm3.501-15.005 2.601-11.439-1.95-.443-2.6 11.438 1.95.444zm12.45-11.435 5.528 23.955 1.949-.45-5.529-23.955-1.948.45zm5.528 23.955.573 2.483 1.949-.45-.573-2.483-1.949.45zM26.791 6a1 1 0 0 1 .975.775l1.949-.45A3 3 0 0 0 26.79 4v2zm-2.356-2a3 3 0 0 0-2.923 2.325l1.949.45A1 1 0 0 1 24.435 6V4z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};

IconPowerGrid.propTypes = {};

export default IconPowerGrid;
